import {
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  Icon,
  List,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core'
import sidebarlogo from '../images/sidebar.png'
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball'
import DomainIcon from '@material-ui/icons/Domain'
import AppBar from '@material-ui/core/AppBar'
import FaceIcon from '@material-ui/icons/Face'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import Collapse from '@material-ui/core/Collapse'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CallIcon from '@material-ui/icons/Call'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FacebookIcon from '@material-ui/icons/Facebook'
import GavelIcon from '@material-ui/icons/Gavel'
import HomeIcon from '@material-ui/icons/Home'
import HttpsIcon from '@material-ui/icons/Https'
import InstagramIcon from '@material-ui/icons/Instagram'
import MenuIcon from '@material-ui/icons/Menu'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import TurnedInIcon from '@material-ui/icons/TurnedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import clsx from 'clsx'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import logo from '../images/logo.png'
import AllLinks from './alllinks'
import EditIcon from '@material-ui/icons/Edit'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  logoTesting: {
    marginTop: 24,
    width: '40%',
    height: '40%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      width: '30%',
      height: '30%',
    },
  },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
  },
  downAppBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '25%',
    [theme.breakpoints.down('sm')]: {
      height: '10%',
    },
    background: '#1B3263',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '25%',
    [theme.breakpoints.down('sm')]: {
      height: '20%',
    },
    // background: 'rgba(0,0,0,0.0)',
    background: 'linear-gradient(180deg,black 0%,transparent 100%)',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  colorBlack: {
    color: '#000000',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  titleFlex: {
    flexGrow: 1,
  },
  lowerCase: {
    textTransform: 'none',
  },
  largeLink: {
    fontSize: 17,
  },
  smallLink: {
    fontSize: 12,
    marginLeft: 18,
    color: '#efefef',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  instagram: {
    background:
      'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
    webkitBackgroundClip: 'text',
    backgroundClip: 'text',
    webkitTextFillColor: 'transparent',
  },
}))

const Header = () => {
  const classes = useStyles()
  const [isTop, setTop] = useState(true)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== isTop) {
        setTop(scrollCheck)
      }
    })
  })

  const [open, setOpen] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [sideBarOtherOption, setSideBarOtherOption] = React.useState(false)

  const openSideOtherOption = () => {
    setSideBarOtherOption(!sideBarOtherOption)
  }

  const [aboutsidebar, setAboutSideBar] = useState(false)
  const openAboutSideBar = () => {
    setAboutSideBar(!aboutsidebar)
  }

  const [activitiesSideBar, setActivitiesSideBar] = useState(false)
  function openActivitiesSideBar() {
    setActivitiesSideBar(!activitiesSideBar)
  }

  const [irsCornerSideBar, setIrsCornerSideBar] = useState(false)
  function openIRSCornerSideBar() {
    setIrsCornerSideBar(!irsCornerSideBar)
  }

  const [publicationsSideBar, setPublicationsSideBar] = useState(false)
  function openPublicationSideBar() {
    setPublicationsSideBar(!publicationsSideBar)
  }

  const [citizenSideBar, setCitizenSideBar] = useState(false)
  function openCitizenSideBar() {
    setCitizenSideBar(!citizenSideBar)
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const [mganchorEl, setMGAnchorEl] = useState(null)
  function closeMGenu() {
    setMGAnchorEl(null)
  }

  function openMGMenu(event) {
    setMGAnchorEl(event.currentTarget)
  }

  const [loginAnchorEl, setLoginAnchorEl] = useState(null)
  function closeLoginMenu() {
    setLoginAnchorEl(null)
  }
  function openLoginMenu(event) {
    setLoginAnchorEl(event.currentTarget)
  }

  const [loginSideBar, setLoginSideBar] = useState(false)
  function openLoginSideBar() {
    setLoginSideBar(!loginSideBar)
  }

  const [mygovSidebar, setMyGovSideBar] = useState(false)
  function openMyGovSideBar() {
    setMyGovSideBar(!mygovSidebar)
  }

  const matches = useMediaQuery('(max-width:1024px)')

  return (
    <div>
      <CssBaseline />
      {isTop ? (
        <Container>
          <AppBar
            position="fixed"
            elevation={0}
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              {matches ? (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={toggleDrawer('left', true)}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              ) : null}
              {/* <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={toggleDrawer('left', true)}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}>
                  <MenuIcon />
                </IconButton>
              </Hidden> */}
              <div variant="h6" className={clsx(classes.titleFlex)}>
                <img src={logo} alt="Logo" className={classes.logoTesting} />
              </div>

              <div>
                <Hidden xsDown mdDown>
                  <Grid container justify="space-between">
                    <Grid item></Grid>
                    <Grid item>
                      {/* <IconButton
                        href="https://twitter.com/IRSAssociation"
                        target="_blank"
                      >
                        <TwitterIcon style={{ color: '#2daae1' }} />
                      </IconButton>
                      <IconButton
                        href="https://www.facebook.com/IRSAssociation/"
                        target="_blank"
                      >
                        <FacebookIcon style={{ color: '#38539b' }} />
                      </IconButton>
                      <IconButton
                        href="https://www.instagram.com/irs_association/"
                        target="_blank"
                      >
                        <InstagramIcon style={{ color: '#f33e00' }} />
                      </IconButton> */}

                      <Button
                        size="large"
                        className={classes.smallLink}
                        component={Link}
                        to="/contactus"
                      >
                        Contact Us
                      </Button>
                      <Button
                        className={classes.smallLink}
                        color="inherit"
                        onClick={openLoginMenu}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Login
                      </Button>

                      <Menu
                        id="simple-menu"
                        anchorEl={loginAnchorEl}
                        keepMounted
                        open={Boolean(loginAnchorEl)}
                        onClose={closeLoginMenu}
                      >
                        <MenuItem
                          onClick={closeLoginMenu}
                          component={Link}
                          to="/links"
                        >
                          IRSA Email
                        </MenuItem>
                        <MenuItem
                          onClick={closeLoginMenu}
                          component={Link}
                          to="/otherutils"
                        >
                          Official email systems
                        </MenuItem>
                      </Menu>
                      <Button
                        className={classes.smallLink}
                        color="inherit"
                        onClick={openMGMenu}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        My Government
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={mganchorEl}
                        keepMounted
                        open={Boolean(mganchorEl)}
                        onClose={closeMGenu}
                      >
                        <MenuItem
                          onClick={closeMGenu}
                          component={Link}
                          to="/links"
                        >
                          Ministry of Finance
                        </MenuItem>
                        <MenuItem
                          onClick={closeMGenu}
                          component={Link}
                          to="/otherutils"
                        >
                          CBDT
                        </MenuItem>
                        <MenuItem
                          onClick={closeMGenu}
                          component={Link}
                          to="/otherutils"
                        >
                          IRS Officers Online
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  <div>
                    <Button
                      size="large"
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/"
                    >
                      Home
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/aboutus"
                    >
                      About Us
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/membership"
                    >
                      Membership
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/activities"
                    >
                      Activities
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/irscorner"
                    >
                      IRS corner
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/publications"
                    >
                      Publications
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/citizencorner"
                    >
                      Citizen Corner
                    </Button>
                  </div>
                </Hidden>
              </div>
            </Toolbar>
            <Grid container justify="center" alignItems="center" align="center">
              <Grid item md={12} xs={12}>
                <hr
                  style={{
                    width: '95%',
                    borderColor: 'rgba(255,255,255,0.2)',
                  }}
                />
              </Grid>
            </Grid>
          </AppBar>
        </Container>
      ) : (
        <Container>
          <AppBar
            position="fixed"
            elevation={0}
            style={{ paddingLeft: 24 }}
            className={clsx(classes.downAppBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              {matches ? (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={toggleDrawer('left', true)}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              ) : null}
              {/* <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={toggleDrawer('left', true)}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}>
                  <MenuIcon />
                </IconButton>
              </Hidden> */}
              <div variant="h6" className={clsx(classes.titleFlex)}>
                <img
                  src={logo}
                  alt="Logo"
                  width="40%"
                  height="40%"
                  className={classes.logoTesting}
                />
              </div>

              <div>
                <Hidden xsDown mdDown>
                  <Grid container justify="space-between">
                    <Grid item></Grid>
                    <Grid item>
                      {/* <IconButton
                        href="https://twitter.com/IRSAssociation"
                        target="_blank"
                      >
                        <TwitterIcon style={{ color: '#2daae1' }} />
                      </IconButton>
                      <IconButton
                        href="https://www.facebook.com/IRSAssociation/"
                        target="_blank"
                      >
                        <FacebookIcon style={{ color: '#38539b' }} />
                      </IconButton>
                      <IconButton
                        href="https://www.instagram.com/irs_association/"
                        target="_blank"
                      >
                        <InstagramIcon style={{ color: '#f33e00' }} />
                      </IconButton> */}

                      <Button
                        size="large"
                        className={classes.smallLink}
                        component={Link}
                        to="/contactus"
                      >
                        Contact Us
                      </Button>
                      <Button
                        className={classes.smallLink}
                        color="inherit"
                        onClick={openLoginMenu}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        Login
                      </Button>

                      <Menu
                        id="simple-menu"
                        anchorEl={loginAnchorEl}
                        keepMounted
                        open={Boolean(loginAnchorEl)}
                        onClose={closeLoginMenu}
                      >
                        <MenuItem
                          onClick={closeLoginMenu}
                          component={Link}
                          to="/links"
                        >
                          IRSA Email
                        </MenuItem>
                        <MenuItem
                          onClick={closeLoginMenu}
                          component={Link}
                          to="/otherutils"
                        >
                          Official email systems
                        </MenuItem>
                      </Menu>
                      <Button
                        className={classes.smallLink}
                        color="inherit"
                        onClick={openMGMenu}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        My Government
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={mganchorEl}
                        keepMounted
                        open={Boolean(mganchorEl)}
                        onClose={closeMGenu}
                      >
                        <MenuItem
                          onClick={closeMGenu}
                          component={Link}
                          to="/links"
                        >
                          Ministry of Finance
                        </MenuItem>
                        <MenuItem
                          onClick={closeMGenu}
                          component={Link}
                          to="/otherutils"
                        >
                          CBDT
                        </MenuItem>
                        <MenuItem
                          onClick={closeMGenu}
                          component={Link}
                          to="/otherutils"
                        >
                          IRS Officers Online
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  <div>
                    <Button
                      size="large"
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/"
                    >
                      Home
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/aboutus"
                    >
                      About Us
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/membership"
                    >
                      Membership
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/activities"
                    >
                      Activities
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/irscorner"
                    >
                      IRS corner
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/publications"
                    >
                      Publications
                    </Button>
                    <Button
                      color="inherit"
                      className={classes.largeLink}
                      component={Link}
                      to="/citizencorner"
                    >
                      Citizen Corner
                    </Button>
                  </div>
                </Hidden>
              </div>
            </Toolbar>
          </AppBar>
        </Container>
      )}
      <React.Fragment>
        <SwipeableDrawer
          className={classes.drawer}
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
        >
          <Divider />
          <List>
            <ListItem
              button
              component={Link}
              to="/"
              onClick={toggleDrawer('left', false)}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                align="center"
              >
                <Grid item>
                  <img src={sidebarlogo} alt="IRS Association Sidebar logo" />
                </Grid>
              </Grid>
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/"
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>

            <ListItem button onClick={openAboutSideBar}>
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText primary="About Us" />
              {aboutsidebar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={aboutsidebar} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {AllLinks.aboutus.map((v, k) => (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to={v.link}
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <Icon style={{ fontSize: 20 }}>{v.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={v.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <ListItem
              button
              component={Link}
              to="/membership"
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <TurnedInIcon />
              </ListItemIcon>
              <ListItemText>Membership</ListItemText>
            </ListItem>

            <ListItem button onClick={openActivitiesSideBar}>
              <ListItemIcon>
                <SportsVolleyballIcon />
              </ListItemIcon>
              <ListItemText primary="Activities" />
              {activitiesSideBar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={activitiesSideBar} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {AllLinks.activities.map((v, k) => (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to={v.link}
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <Icon style={{ fontSize: 20 }}>{v.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={v.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem button onClick={openIRSCornerSideBar}>
              <ListItemIcon>
                <MeetingRoomIcon />
              </ListItemIcon>
              <ListItemText primary="IRS Corner" />
              {irsCornerSideBar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={irsCornerSideBar} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {AllLinks.irscorner.map((v, k) => (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to={v.link}
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <Icon style={{ fontSize: 20 }}>{v.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={v.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem button onClick={openPublicationSideBar}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Publications" />
              {publicationsSideBar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={publicationsSideBar} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {AllLinks.publications.map((v, k) => (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to={v.link}
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <Icon style={{ fontSize: 20 }}>{v.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={v.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem button onClick={openCitizenSideBar}>
              <ListItemIcon>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary="Citizen Corner" />
              {citizenSideBar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={citizenSideBar} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {AllLinks.citizencorner.map((v, k) => (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to={v.link}
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <Icon style={{ fontSize: 20 }}>{v.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={v.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem
              button
              component={Link}
              to="/contactus"
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <CallIcon />
              </ListItemIcon>
              <ListItemText>Contact Us</ListItemText>
            </ListItem>

            <ListItem button onClick={openLoginSideBar}>
              <ListItemIcon>
                <HttpsIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
              {loginSideBar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={loginSideBar} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {AllLinks.login.map((v, k) => (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to={v.link}
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <Icon style={{ fontSize: 20 }}>{v.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={v.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem button onClick={openMyGovSideBar}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="My Government" />
              {mygovSidebar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={mygovSidebar} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {AllLinks.mygov.map((v, k) => (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to={v.link}
                    onClick={toggleDrawer('left', false)}
                  >
                    <ListItemIcon>
                      <Icon style={{ fontSize: 20 }}>{v.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText secondary={v.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            {/* <ListItem
              button
              component={Link}
              to="/"
              onClick={toggleDrawer('left', false)}
            > */}

            {/* </ListItem> */}
          </List>
          <Grid container justify="center" alignItems="center" align="center">
            <Grid item>
              <IconButton
                href="https://twitter.com/IRSAssociation"
                target="_blank"
              >
                <TwitterIcon style={{ color: '#2daae1' }} />
              </IconButton>
              <IconButton
                href="https://www.facebook.com/IRSAssociation/"
                target="_blank"
              >
                <FacebookIcon style={{ color: '#38539b' }} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/irs_association/"
                target="_blank"
              >
                <InstagramIcon style={{ color: '#f33e00' }} />
              </IconButton>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  )
}

export default Header
